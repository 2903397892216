<template>
  <div class="alert alert-info text-center" role="alert">
    <a v-if="disabled" @click="enable" href="#" class="h5 text-danger"><i class="text-danger fas fa-mail-bulk"></i> Notifications are disabled</a>
    <a v-else @click="disable" href="#" class="h5 text-success"><i class="text-success fas fa-mail-bulk"></i> Notifications are enabled</a>
  </div>
</template>
<script>
import api from '@/clients/api'
import Vue from 'vue'

export default {
  data() {
    return {
      disabled: null
    }
  },
  computed: {
    activeUser() {
      return activeUser()
    },
  },
  mounted() {
    return new Promise((resolve, reject) => {
      api.getNotificationsStatus().then(response => {
        this.disabled = response.data
        resolve()
      })
    })
  },
  methods: {
    disable() {
      return new Promise((resolve, reject) => {
        api.disableNotifications().then(response => {
          this.disabled = response.data
          resolve()
        })
      })
    },
    enable() {
      return new Promise((resolve, reject) => {
        api.enableNotifications().then(response => {
          this.disabled = response.data
          resolve()
        })
      })
    }
  }
}
</script>
